import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./styles.css";

const Hero = () => {
  return (
    <div id="heroSection" className="heroPadding relative bg-gray-50">
        
        <main  className="">
            <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
            <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                <h1 className="text-5xl tracking-tight font-extrabold text-gray-900">
                Kastle
                <span className="text-red-800"> Restoration</span>
                </h1>
                <p className="mt-1 max-w-md mx-auto text-lg text-gray-900 md:max-w-xl">
                Chicago Restoration Services, tons of services provided below on the site. Contact us today, just click below!
                </p>
                <div className="mt-5 sm:flex sm:justify-center lg:justify-start">
                <div className="w-2/3 max-w-xs mx-auto lg:mx-0 rounded-md shadow">
                    <a
                    href="#"
                    className="flex items-center justify-center text-lg md:text-xl px-8 py-3 border border-transparent font-medium text-white bg-red-800 hover:bg-red-900 md:py-4 md:px-10"
                    >
                    Contact Us
                    </a>
                </div>
                </div>
            </div>
            </div>
            <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                <StaticImage 
                    className="absolute inset-0 w-full h-full object-cover"
                    src="../../images/heroImage.jpg" 
                    alt="Example of brick wall that was built recently."
                />
            </div>
        </main>

    </div>
  )
}

export default Hero;