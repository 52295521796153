import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./styles.css";

const IncentiveSection2 = () => {
  return (
    <div id="projectSection" className="bg-gray-200">
        <div className="mx-auto pt-32 lg:pt-26 pb-20">
            <div className="lg:w-2/3 mx-auto px-5">
                <div className="text-gray-900 text-center max-w-md sm:max-w-md md:max-w-2xl mx-auto pb-14">
                    <h1 className="font-extrabold text-5xl lg:text-6xl pb-5">Recent Projects</h1>
                    <p className="font-medium tracking-wide lg:tracking-tight lg:text-lg">
                    This is a family-owned and operated company, we have been providing services for the greater Chicagoland area for almost 30 years, we specialize in masonry restoration.
                    </p>
                </div>
            </div>

            <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
                <div class="md:flex flex-wrap -m-1 md:-m-2">
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="relative block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider14.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                            
                        </div>
                        {/* <div className="bg-black w-full h-full z-10 opacity-50 hover:opacity-0 transition duration-400"></div> */}
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider4.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider3.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider6.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider2.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider1.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider12.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider11.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider10.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider8.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider7.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap md:w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <StaticImage 
                                className="block object-cover object-center w-full galleryImage md:hover:shadow-lg"
                                src="../../images/slider5.png"
                                alt="Fancy Looking Goat Here For A Good Time."
                            />
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
  )
}

export default IncentiveSection2;

                       
                        