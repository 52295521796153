import * as React from "react";

const About = () => {
  return (
    <div id="aboutSection" className="bg-gradient-to-t from-gray-300 to-gray-50 py-20 px-5">

        <div className="mx-auto pt-32 pb-10">
            <div className="lg:w-2/3 mx-auto px-5 md:pt-20 lg:pt-0">
                <div className="text-gray-900 text-center max-w-md sm:max-w-md md:max-w-2xl mx-auto">
                    <h1 className="font-extrabold text-5xl pb-5">About Kastle Restore</h1>
                    <p className="font-medium tracking-wide lg:tracking-tight lg:text-lg">
                    This is a family-owned and operated company, we have been providing services for the greater Chicagoland area for almost 30 years, we specialize in masonry restoration jobs, we make old buildings new again, we perform services such as tuckpointing, waterproofing, chemical washing, window, and door beam replacement, rebuilding sections of entire walls and chimneys. We are licensed in the City of Chicago and surrounding areas. We provide a warranty and free maintenance for every job we do.
                    </p>
                </div>
            </div>
        </div>

        <div id="serviceSection" className="text-center max-w-sm sm:max-w-md md:max-w-xl mx-auto">
            <h1 className="font-extrabold pb-5 pt-32 text-5xl">Our Services</h1>
            <p className="font-semibold lg:text-lg">
            This is a family-owned and operated company, we have been providing services for the greater Chicagoland area for almost 30 years, we specialize in masonry restoration jobs, we make old buildings new again, we perform services such as tuckpointing, waterproofing, chemical washing, ect.
            </p>
        </div>

        <div className="text-center md:grid sm:grid-cols-2 lg:grid-cols-4 max-w-xs md:max-w-lg lg:max-w-3xl text-xl mx-auto space-y-10 md:space-y-0 mt-10 md:gap-10">
            <div>
                <div className="bg-gray-200 text-center text-2xl text-white py-20 lg:py-32 rounded-md shadow tracking-wide">
                    {/* Image */}
                </div>
                <h2 className="mt-2">Beam Restore</h2>
            </div>
            <div>
                <div className="bg-gray-200 text-center text-white py-20 lg:py-32 rounded-md shadow tracking-wide">
                    
                </div>
                <h2 className="mt-2">Waterproofing</h2>
            </div>
            <div>
                <div className="bg-gray-200 text-center text-white py-20 lg:py-32 rounded-md shadow tracking-wide">
                    {/* Image */}
                </div>
                <h2 className="mt-2">Tuckpointing</h2>
            </div>
            
            <div>
                <div className="bg-gray-200 text-center text-white py-20 lg:py-32 rounded-md shadow tracking-wide">
                    {/* Image */}
                </div>
                <h2 className="mt-2">Chemical Washing</h2>
            </div>
        </div>
    </div>
  )
}

export default About;