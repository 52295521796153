import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";


const Footer = () => {
  return (
    <div className="bg-gradient-to-b from-red-800 to-red-900">
        <div className="lg:flex justify-between lg:max-w-7xl lg:px-10 lg:pb-0 mx-auto">
            <div className="flex flex-col py-5">
                <div className="flex justify-center">
                    <AnchorLink
                        to="#heroSection"
                        className="flex items-center">
                    <StaticImage 
                        className="w-8"
                        src="../../images/LogoWhite.png" 
                        alt="Example of brick wall that was built recently."
                    />
                        <h2 className="text-gray-100 text-2xl font-semibold uppercase pt-1">astle Restore</h2>
                    </AnchorLink>
                </div>
                <div className="text-gray-100 md:ml-6 flex md:items-center justify-center space-x-2 text-md pb-5">
                  <h2>You can also reach us on Facebook.</h2>
                  <Link to="https://www.facebook.com/KastleLLC" className="transition ease-in-out delay-50 duration-300 text-gray-50 hover:text-gray-300">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        aria-hidden="true"
                      >
                        <path
                          d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                          fill="currentColor"
                        />
                      </svg>
                  </Link>
                </div>
            </div>
            
            <div className="text-center pb-10 lg:pb-5">
            <h3 className="lg:hidden font-semibold text-gray-100 tracking-wider uppercase border-b border-lightTan-200 w-max mx-auto mb-2">Quick Links</h3>
                <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-10 lg:pt-10 text-lg tracking-wide text-gray-50">
                    <AnchorLink
                        to="#aboutSection"
                        className="transition ease-in-out delay-50 duration-300 hover:text-gray-300 lg:mt-1"
                    >About
                    </AnchorLink>
                    <AnchorLink
                        to="#serviceSection"
                        className="transition ease-in-out delay-50 duration-300 hover:text-gray-300 lg:mt-1"
                    >
                        Services
                    </AnchorLink>
                    <AnchorLink
                        to="#contactSection"
                        className="transition ease-in-out delay-50 duration-300 hover:text-gray-300 lg:mt-1"
                    >
                        Contact
                    </AnchorLink>
                    <AnchorLink
                        to="#projectSection"
                        className="transition ease-in-out delay-50 duration-300 hover:text-gray-300 lg:mt-1"
                    >
                        Projects
                    </AnchorLink>
                </div>
            </div>

            
        </div>

        <div className="border-t border-gray-200 py-5">
            <p className="text-base text-gray-200 text-center">&copy; 2022 Patrick Suchy All rights reserved.</p>
        </div>
    </div>
  )
}

export default Footer;