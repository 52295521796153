import * as React from "react"
import Header from "../components/headerSection";
import NavBar from "../components/navBar";
import Hero from "../components/heroSection";
import About from  "../components/aboutSection";
import Contact from "../components/contactSection";
import Footer from "../components/footerSection";
import IncentiveSection2 from "../components/incentiveSection2";

const IndexPage = () => {
  return (
    <main>
      <div className="fixed z-50 w-full shadow">
        <Header />
        <NavBar />
      </div>
      <Hero />
      <About />
      <Contact />
      <IncentiveSection2 />
      <Footer />
    </main>
  )
}

export default IndexPage
